<template>
   <a
      class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--bubble-ripple1">
      <div class="ph-inpage-widget__circle">
        <div class="ph-inpage-widget__circle-thumb">
          <img :src="imgIcon" v-show="imgIcon" alt="">
          <img :src="require('@/assets/panel/images/inpage/empty-white.svg')" v-show="!imgIcon" alt="">
        </div>
        <div class="ph-inpage-widget__circle-notification">1</div>
        <div class="ph-inpage-widget__circle-border"></div>
        <div class="ph-inpage-widget__close">&#10005;</div>
      </div>
    </a>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>